@import '@/modules/ui/utils/mixins';

* {
  box-sizing: border-box;
}

body {
  --max-content-width: #{px-to-rem(1260px)};

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: var(--mui-palette-text-primary);
}

/* stylelint-disable-next-line selector-class-pattern */
.MuiInputBase-input {
  box-sizing: content-box;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: unset !important;
  caret-color: inherit !important;
  -webkit-text-fill-color: inherit !important;
}

/* stylelint-disable-next-line selector-class-pattern */
#ot-sdk-btn-floating.ot-floating-button {
  display: none;
}
